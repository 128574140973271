/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { AxiosResponse } from 'axios';
import { api } from './api';

const getCustomerPlan = async (
  planId: string | number,
): Promise<AxiosResponse<Services.User.Response.GetCustomerPlan>> => {
  const url = `user/customerplans/${planId}`;
  return api.get(url);
};

const getCustomerPlanId = async (
  planId: string | number,
): Promise<AxiosResponse<Services.User.Response.GetCustomerPlanId>> => {
  const url = `user/customerplansid/${planId}`;
  return api.get(url);
};

const getCustomerPlansLive = (
  idCustomer: any,
): Promise<AxiosResponse<Services.User.Response.GetCustomerPlansLive>> => {
  const url = `user/customerplanslivelist/${idCustomer}`;
  return api.get(url);
};

const getAllCustomerPlans = (
  idCustomer: any,
): Promise<AxiosResponse<Services.User.Response.GetAllCustomerPlans>> => (
  api.get(`user/customerplanslist/${idCustomer}`)
);

const getCustomerPlansPlayerWebTV = (
  idCustomerPlans: any,
): Promise<AxiosResponse<Services.User.Response.GetCustomerPlansPlayerWebTV>> => {
  const url = `user/customerplansplayerwebtvlist/${idCustomerPlans}`;
  return api.get(url);
};

const getHotsiteMediaSocial = (
  id: any,
): Promise<AxiosResponse<Services.User.Response.GetHotsiteMediaSocial>> => {
  const url = `user/hotsitemediasocial/${id}`;
  return api.get(url);
};

const getAllHotsiteMediaSocial = (
): Promise<AxiosResponse<Services.User.Response.GetAllHotsiteMediaSocial>> => (
  api.get('user/hotsitemediasocial')
);

const createHotsiteMediaSocial = (
  hotsiteMediaSocialData: any,
): Promise<AxiosResponse<Services.User.Response.CreateHotsiteMediaSocial>> => (
  api.post('user/hotsitemediasocial', hotsiteMediaSocialData)
);

const updateHotsiteMediaSocial = (
  id_customer_plans: string | number,
  hotsiteMediaSocialData: any,
): Promise<AxiosResponse<Services.User.Response.UpdateHotsiteMediaSocial>> => (
  api.put(`user/hotsitemediasocial/${id_customer_plans}`, hotsiteMediaSocialData)
);

const createSelectedPlansId = (
  categoryId: string | number,
  customerPlansCategoryData: any,
): Promise<AxiosResponse<Services.User.Response.CreateSelectedPlansId>> => {
  const url = `user/customerplansid/${categoryId}/updateSelectedPlanId`;
  return api.post(url, customerPlansCategoryData);
};

export const user = {
  getCustomerPlan,
  getCustomerPlanId,
  getCustomerPlansLive,
  getAllCustomerPlans,
  getCustomerPlansPlayerWebTV,
  getHotsiteMediaSocial,
  getAllHotsiteMediaSocial,
  createHotsiteMediaSocial,
  updateHotsiteMediaSocial,
  createSelectedPlansId,
};
