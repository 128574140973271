
import Vue from 'vue';
import {
  BaseInput,
  BaseMenu,
} from '@/components/BaseComponents';

export default Vue.extend({
  name: 'GalleryTopbar',

  components: {
    BaseInput,
    BaseMenu,
  },

  props: {
    categoryItems: {
      type: Array as () => Views.ViewGallery.CategoryItems[],
      required: true,
    },
  },

  data() {
    return {
      categoryMenuIsOpen: false,

      periodItems: [
        { text: 'all', value: 'ALL' },
        { text: 'yesterday', value: 'YESTERDAY' },
        { text: 'this_week', value: 'THIS_WEEK' },
        { text: 'last_7_days', value: 'LAST_7_DAYS' },
        { text: 'this_month', value: 'THIS_MONTH' },
        { text: 'last_30_days', value: 'LAST_30_DAYS' },
      ] as Views.ViewGallery.PeriodItems[],
      periodMenuIsOpen: false,

      searchValue: '',
      selectedCategory: 0,
      selectedPeriod: 'ALL' as Views.ViewGallery.Periods,
    };
  },

  computed: {
    selectedCategoryText(): string {
      const selectedItem = this.categoryItems.find((item) => item.value === this.selectedCategory);
      return selectedItem ? this.$t(selectedItem.text).toString() : '';
    },

    selectedPeriodText(): string {
      const selectedItem = this.periodItems.find((item) => item.value === this.selectedPeriod);
      return selectedItem ? this.$t(selectedItem.text).toString() : '';
    },
  },

});
