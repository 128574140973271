
import Vue from 'vue';
import { Helpers } from '@/utils';
import {
  BaseCard,
  BaseIcon,
} from '@/components/BaseComponents';

export default Vue.extend({
  name: 'GalleryBasicVideoData',

  components: {
    BaseCard,
    BaseIcon,
  },

  props: {
    video: {
      type: Object as () => Views.ViewGallery.Video,
      required: true,
    },
  },

  methods: {
    formatDate(date: string) {
      return Helpers.formatDate(this.$root.$i18n.locale as 'en-US' | 'pt-BR', date);
    },
  },
});
